jQuery(document).ready(function($){
    function mouseIn(e){
      $(e.currentTarget).addClass('over');
      $(e.currentTarget).find('.dropdown-menu').velocity("slideDown", { });
    }
    function mouseOut(e){
      $(e.currentTarget).removeClass('over');
       $(e.currentTarget).find('.dropdown-menu').velocity("slideUp", { });
    }
    $("#menu-main-menu .dropdown").hoverIntent(mouseIn, mouseOut);
    
}); 