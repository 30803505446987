/******************************************************************************************************************
*******************************************************************************************************************
	Match Height
*******************************************************************************************************************
******************************************************************************************************************/


function matchHeightCall(){
  jQuery('.home .tile .tile-content').matchHeight(true);	 

}



jQuery(document).ready(function($){


  matchHeightCall();
  

  $(window).on( "orientationchange", function(){
   matchHeightCall();
  });

  

  
  $(window).resize(matchHeightCall());




onAlways = function(){
  matchHeightCall(); 
};
  



if(!$('html').hasClass('ie8')){



try{
var imgLoad = imagesLoaded('img');

// bind with .on()
imgLoad.on( 'always', onAlways );


}catch(e){}

 
}




});




