/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var BrowserDetect = {
      init: function () {
          this.browser = this.searchString(this.dataBrowser) || "Other";
          this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
      },
      searchString: function (data) {
          for (var i = 0; i < data.length; i++) {
              var dataString = data[i].string;
              this.versionSearchString = data[i].subString;

              if (dataString.indexOf(data[i].subString) !== -1) {
                  return data[i].identity;
              }
          }
      },
      searchVersion: function (dataString) {
          var index = dataString.indexOf(this.versionSearchString);
          if (index === -1) {
              return;
          }

          var rv = dataString.indexOf("rv:");
          if (this.versionSearchString === "Trident" && rv !== -1) {
              return parseFloat(dataString.substring(rv + 3));
          } else {
              return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
          }
      }, 

      dataBrowser: [
          {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
          {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
          {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
          {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
          {string: navigator.userAgent, subString: "Safari", identity: "Safari"},
          {string: navigator.userAgent, subString: "Opera", identity: "Opera"}
      ]

  };
  
BrowserDetect.init();



(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $loader = $("#loader");
        function collage() {
           $('.gallery-handler').collagePlus({
              'effect' : 'effect-1',
              'allowPartialLastRow' : false,
              'targetHeight'    : 250 
            }); 
        }       
        var showLoader = function(){
          $("html").removeClass('loaded');
          $loader.velocity({ opacity: 1 }, { 
            duration: 700, 
            begin:function(e){
              $(e).css('display','block');
            }
          });
        };
        
        var hideLoader = function(){
          $("html").addClass('loaded');
          $loader.velocity({ opacity: 0 }, { 
            duration: 700, 
            complete:function(e){
              $(e).css('display','none');
            }
          });
          if($(".gallery-handler").length > 0){
            collage();
          }
          if($(".swipe-box").length > 0){
            $( '.swipe-box' ).swipebox();  
          }
          
        };



      $(window).bind('resize', function() {
          collage();
      });
              
      if(BrowserDetect.browser === "Explorer" && BrowserDetect.version === 8){      
          hideLoader();
      }else{
        
        $('.l-page').imagesLoaded()
        .always( function( instance ) {
          hideLoader();


        }).fail( function() {
          hideLoader();
        }); 
        
        $(window).load(function(){
          viewportUnitsBuggyfill.init({refreshDebounceWait: 250});
        });
             
      }
      
      if(BrowserDetect.browser !== "Explorer" && BrowserDetect.version > 8){
        
        $(window).load(function(){
          viewportUnitsBuggyfill.init({refreshDebounceWait: 250});
        });
        
      }
        
        if(BrowserDetect.browser !== "Explorer" && BrowserDetect.version > 8){      
          
          if(!Modernizr.svgasimg){
            // $('img').each(function() {
            //   var img_src = $(this).attr('src');
            //   var new_src = img_src.replace(/\.svg$/, '.png');
            //   $(this).attr('src', new_src);
            // });
          }
          if(!Modernizr.touch){
            $("a[href*='tel:']").on('click',function(){
              return false;
            }); 
          }
          if(Modernizr.input.placeholder){
            $("label").hide();
          }
        }

 

      },
      finalize: function() {
        

       
  




      }
    },
    // Home page
    'home': {
      init: function() {
       
                     var $header = $('.hero-header'),
                          $slider_content = $('.hero-content p'),
                          $slider_btn = $('.hero-text .btn.cta'),
                          $slider_text = $('.hero-text');
                          
                      var loadFadeIn = [
                          { elements: $header, properties: { translateX: 0, opacity:1, scale:1 }, options: { duration: 300, delay:500 } },
                          { elements: $slider_content, properties: { translateX: 0, opacity:1,scale:1 }, options: { duration: 300, delay:150 } },
                          { elements: $slider_btn, properties: { translateX:0, opacity:1, scale:1}, options: { duration: 300, delay:150 } }
                      ];
                      
                
                

                
                      
                      var animationFrame = new AnimationFrame(60);
                      var ticking = false;
                      var scrollVar = $(window).scrollTop();
                      var $slider = $('.slick-slider');
                       
             
                      function heroScroll(){
                        ticking = false;
                        scrollVar = $(window).scrollTop();
                        var limitedScrollVar = scrollVar*0.10;
                        var rightPercentage = 100;
                        if($("html").outerWidth() >= 1100 && $("html").hasClass('no-touch')){
                          $('.slick-list').css({
                            'transform': 'translate3d(0px,'+(0.4*scrollVar) * 1+'px,0px)',
                          });
                          $slider_text.css({
                             'opacity': (1-(0.00157058823529*scrollVar))
                          });
                        }
                      }
                  
                               function requestTick() {
                        if(!ticking) {
                          animationFrame.request(heroScroll);
                        }
                        ticking = true;
                      }
                      
                      
                      
                      
                       
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      $slider.on('init', function(event, slick){
                        $.Velocity.RunSequence(loadFadeIn);
                        requestTick();
                        
                          $('.mobile-nav-trigger, .mobile-nav-trigger span').on('click', function(event){
                            $slider[0].slick.setPosition();
                          });
                            
                      }); 
                      
                      
                      $slider.ready(function(){
                        $('.slick-slider').slick({
                          infinite: true,
                          autoplay: true,
                          autoplaySpeed: 7000, 
                          fade: true,
                          cssEase: 'linear',
                          speed: 900,
                          arrows:false, 
                          swipe: false,
                          swipeToSlide: false,
                          dots: true,
                          init: function(e){
                
                 
                          }
                        });

                        
                        
                      });
                				
                      $(window).on('scroll', function(){
                        requestTick();
                      });



               },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
     'our_team': {
      init: function() {
         
      },
       finalize: function() {


              function openOffCanvas($this){
                content_number = $this.data('canvas-trigger');    
                $('.l-page, .overlay-off-canvas, .off-canvas-content.'+content_number).addClass('open');
              }
              function closeOffCanvas(){
                $('.l-page, .off-canvas-content,.overlay-off-canvas').removeClass('open');
              }


              var off_canvas_content = $('.hidden.off-canvas-content').clone().removeClass('hidden').addClass('cloned');
               
              $('body').append(off_canvas_content)
              .queue(function(){
                $('.name-handler').matchHeight(true);   


                $(document).keyup(function(e){
                
                    if(e.keyCode === 27){
                    closeOffCanvas();
                  }
                
                });
                
                $('.off-canvas-content .close, .overlay-off-canvas').click(function(e){
                  e.preventDefault();
                  closeOffCanvas();
                });
                  
                $('.off-canvas-trigger ').click(function(e){
                  e.preventDefault();
                  openOffCanvas($(this));
                });


                $('.print-bio').on('click', function(e){

                  printing_div = $(this).closest('.off-canvas-content');
                  //console.log(printing_div);
                  printSelection(printing_div);
                  return false;
                });

                $(this).dequeue();
              });














       }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
