function gmapInit() {
  if($("#map-canvas").length === 0){
    return false;
  }
  var myLatlng = new google.maps.LatLng(50.025452, -127.377232);
  //var image = '/fishfishfishfishandsalmonisgood/wp-content/themes/walterscove/assets/img/map-pin.png';
  var mapOptions = {
    zoom: 15,
    center: myLatlng,
    panControl: false,
    zoomControl: true,
    scaleControl: false,
    };
  if(Modernizr.touch){
    mapOptions.draggable = false;
  }
  var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

  var marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      title: 'Walters Cove',
     // icon: image
  });
}