(function($) {
  'use strict';
  $(function(){
    $('.accordion-header').toggleClass('inactive-header');
      // The Accordion Effect
      $('.accordion-header').click(function (e) {
        e.preventDefault();
        if($(this).is('.inactive-header')) {
          $('.active-header').toggleClass('active-header').toggleClass('inactive-header').next().slideToggle().toggleClass('open-content');
          $(this).toggleClass('active-header').toggleClass('inactive-header');
          $(this).next().slideToggle().toggleClass('open-content');
        }
        else {
          $(this).toggleClass('active-header').toggleClass('inactive-header');
          $(this).next().slideToggle().toggleClass('open-content');
        }
      });
  });
})(jQuery);